<template>
  <div class="authentication">
    <div class="titleBox d-flex align-items-center">
      <div class="imgBox4" @click="handleClickGoBack">
        <img src="../../assets/images/login/chevron-left.png" alt="" />
      </div>
      <div class="title">{{ $t("AuthPage.164@sign_in") }}</div>
    </div>

    <div class="mt-4">
      <div class="form-group">
        <!-- <label>{{ $t("AuthPage.281@google_captcha") }}</label> -->
        <label>Enter your Google Authenticator code here</label>
        <!-- <input class="form-control mb-0" :placeholder="$t('AuthPage.281@google_captcha')" v-model="form.token"/> -->
        <input class="form-control mb-0" placeholder="Enter your Google Authenticator code here" v-model="form.token" />
      </div>
      <div class="d-flex justify-content-between">
        <span class="
            dark-color
            d-inline-block
            line-height-2
            d-flex
            align-items-center
          ">
          <router-link :to="{ name: 'auth1.recovery' }">{{ $t("AuthPage.e85@lost_google_authenti") }}?</router-link>
        </span>
        <!-- <router-link :to="{ name: 'social.home' }"> -->
        <button type="submit" class="btn btn-primary float-right" @click="handleClickLogin">
          {{ $t("AuthPage.164@sign_in") }}
        </button>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Authentication',
    data: () => ({
      form: {
        token: ''
      }
    }),
    mounted() { },
    methods: {
      // 回退到登录页
      handleClickGoBack() {
        this.$router.push({ name: 'auth1.sign-in1' })
      },
      // 2fa验证
      handleClickLogin() {
        let userLoginInfo = JSON.parse(this.$getlocalStorage('userLoginInfo'))
        const data = {
          ...userLoginInfo,
          ...this.form
        }
        this.$http.login2Fa(data).then((res) => {
          if (res.status == 200) {
            let token = res.data.token;
            let userInfo = res.data.user_info;
            this.$setlocalStorage("token", token);
            this.$setlocalStorage("userInfo", JSON.stringify(userInfo));
            let fromRouter = window.localStorage.getItem('fromRouter') || ''
             if (fromRouter) {
              this.$router.push(fromRouter)
              window.localStorage.removeItem('fromRouter')
            } else {
              this.$router.push({ name: 'social.home' })
            }
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  @media (max-width: 578px) {
    .authentication {
      padding-left: 10px;
    }
  }

  .authentication {
    .titleBox {
      margin-bottom: 83px;
      position: relative;

      .imgBox4 {
        position: absolute;
        left: -10px;
        cursor: pointer;
      }

      .title {
        font-size: 28px;
        font-weight: 400;
        padding-left: 20px;
        color: #3f414d;
      }
    }
  }
</style>